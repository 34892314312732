import React from 'react';
import logo from './invertiry.png';
import { Link } from "gatsby"

class Footer extends React.Component {

    render() {
        return (
            <div className="f-section">
                <div className="container">
                    <div className="row">
                    <div className="col-md-3 col-xs-12 w_linkbox">
                            <div className="widget-header">Invertiry.com</div>
                            <ul className="w-list">
                            
                                <li className="w-footer__linkbox-item">
                                    <Link to="/politica-de-privacidad-y-cumplimiento-de-gdpr" className="w-footer__linkbox-link">
                                    Política de Privacidad
                        </Link>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <Link to="/risk-statement-terms-and-conditions" className="w-footer__linkbox-link">
                                    Risk Statement
                        </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-xs-12 w_linkbox">
                            <div className="widget-header">Academia De Aprendizaje</div>
                            <ul className="w-list">
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/muestra-de-analisis-tecnico" className="w-footer__linkbox-link">
                                    Muestra de Análisis Técnico
                        </a>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/administracion-del-dinero" className="w-footer__linkbox-link">
                                    Administración del Dinero
                        </a>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/estrategias-de-opciones-binarias" className="w-footer__linkbox-link">
                                    Estrategias de Opciones Binarias
                        </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-xs-12 w_linkbox">
                            <div className="widget-header">Sobre Forex</div>
                            <ul className="w-list">
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/forex-y-trading-con-cfd" className="w-footer__linkbox-link">
                                    Forex y Trading con CFD
                        </a>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/lista-de-los-mejores-broker-de-forex" className="w-footer__linkbox-link">
                                    Mejores Bróker de Forex
                        </a>
                                </li>
            
                            </ul>
                        </div>
                        <div className="col-md-3 col-xs-12 w_linkbox">
                            <div className="widget-header">Acerca De Las Opciones Binarias</div>
                            <ul className="w-list">
                                
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/experiencia-de-opciones-binarias" className="w-footer__linkbox-link">
                                    Experiencia De Opciones Binarias
                        </a>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/opciones-binarias-consejos-patrones-de-trading-resenas-de-brokers-y-mucho-mas" className="w-footer__linkbox-link">
                                    ¿Qué son las opciones binarias?
                        </a>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <a href="https://www.invertiry.com/estafa-de-opciones-binarias" className="w-footer__linkbox-link">
                                    Estafa de Opciones Binarias
                        </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="footer-green">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="row">
                                    <div className="col-md-3 col-xs-12 logo-f">
                                        <img className="f_logo" src={logo} alt="invertiry" />
                                        <p className="f-fs">Follow Us</p>
                                        <div className="social-network social-circle">
                                        <p><a href="https://www.facebook.com/invertiry" className="icoTwitter" title="Facebook">
                                        <svg height="25px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                        </a>
                                        <a href="https://www.linkedin.com/company/invertiry" className="icoYoutube" title="Linkedin">
                                        <svg height="25px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                        </a></p>
                                        
                                     
                                </div>
                                    </div>
                                    <div className="col-md-9 col-xs-12 seo-text-f">
                                    ADVERTENCIA DE RIESGO:
                                    Los productos financieros ofrecidos por la compañía conllevan un alto nivel de riesgo y pueden resultar en la pérdida de todos sus fondos. No debería nunca invertir dinero que no pueda permitirse perder.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;