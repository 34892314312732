import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import logo from './invertiry.png';

class Header extends React.Component {

    render() {
        const rootPath = `${__PATH_PREFIX__}/`
        return (
            <header className="header-main">

                <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light"><div className="container">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {this.props.tagName}
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/academia-de-aprendizaje">Academia De Aprendizaje</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/revision-de-broker">Revisión de Broker</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/mejores-brokers-de-opciones-binarias">Mejores Brokers de Opciones Binarias</Link>
                            </li> 
                        </ul>
                    </div></div>
                </nav>

            </header>
        )
    }
}

export default Header;