import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from './Sidebar'
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import logo from './invertiry.png';
import $ from 'jquery';

class Layout extends React.Component {
  componentDidMount() {
    // setTimeout(() => {
    //   if ('loading' in HTMLImageElement.prototype) {
    //     const images = document.querySelector.toArray();
    //     images.forEach(img => {
    //       console.log(img.dataset.src);
    //       img.src = img.dataset.src;
    //     });
    //   } else {
    //     // Dynamically import the LazySizes library
    //     const script = document.createElement('script');
    //     script.src =
    //       'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
    //     document.body.appendChild(script);
    //   }
    // }, 1500);
  }
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link className="navbar-brand header__logo" to="/">
            <img className="logo-header" src={logo} alt="invertiry" />
            <p> Forex & Opciones Binarias</p>
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
        >
          <Link className="navbar-brand header__logo" to="/">
            <img className="web-header__logo" src={logo} alt="invertiry" />
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        <div className="container">
          <div className="row">
            {children}
            <Sidebar />
          </div>
        </div>
        <Footer />

      </Fragment>
    )
  }
}

export default Layout
