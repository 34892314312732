import React from "react"

const olymptradeLink = [
  "resena-de-olymp-trade-corredor-legitimo-o-una-estafa",
  "como-depositar-y-retirar-dinero-en-olymp-trade",
  "problemas-al-depositar-y-retirar-dinero-en-olymp-trade",
  "resena-de-expert-option-es-una-estafa-del-trading-o-una-empresa-confiable",
  "estrategias-de-opciones-binarias",
  "resena-de-deriv-deriv-es-un-buen-broker-para-usted",
]
const lstiqoption = [
  "resena-de-iq-option-es-un-broker-de-estafa-o-legitimo",
]
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: undefined,
    }
  }
  componentDidMount() {
    const link = window.location.pathname.replace("/", "");
    this.setState({ link });
  }
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12">
        <div className="sidebar sticky-top">
        <div className="sidebar-rev">

{(this.state.link && olymptradeLink.some(x => x === this.state.link)) ?
  <>
    <div className="panel panel-default">
      <div className="panel-heading">
      Regístrese en Olymp Trade Obtenga $ 10,000 gratis en la cuenta DEMO
      </div>
      <div className="panel-body">
        {<iframe src="https://olymptrade.com/lands/affiliate-new-form/index.html?lang=es&dark=false&square=false&horizontal=false&subid1=invertiry-form&subid2=&affiliate_id=1060014&no_redirect=1&freereg=false&auth=false" width="100%" height="320" frameborder="0"></iframe>}
      </div>
    </div>
  </>
  : null
}
</div>

<div className="sidebar-rev">

              {(this.state.link && lstiqoption.some(x => x === this.state.link)) ?
                <>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                    REGÍSTRESE EN IQ OPTION OBTENGA $ 10,000 GRATIS EN LA CUENTA DEMO
                    </div>
                    <div className="panel-body">
                      {<iframe src="https://iqbroker.com/land/registration/es/?aff=168082&afftrack=invertiry-form" width="100%" height="730" frameborder="0"></iframe>}
                    </div>
                  </div>
                </>
                : null
              }
            </div>
            {/* <div style={{ textAlign: 'center' }}>
              <a href="https://one.exness.link/a/c_0buwzjm5sb">
              <img src="https://d3dpet1g0ty5ed.cloudfront.net/ES_0921_Crypto_DevColor_Want_better_digi_300x250px.png" width="300" height="250" alt="" />
              </a>
            </div> */}
            <div style={{ textAlign: 'center' }}>
              <a href="https://qtxbrk.com/sign-up/fast/?lid=70867">
              <img src="https://static.quotex.io/files/10_en/300_250.jpg"></img>
              </a>
            </div>
            <div className="sidebar-rev" style={{ marginTop: 10 }}>
              <div className="panel panel-default">
                <div className="panel-heading">
                CORREDORES RECOMENDADOS
                </div>
                <div className="panel-body">
                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.invertiry.com/iqoption" rel="nofollow" title="IQ Option">
                        <img alt="iq option" src="https://imgdmf5.s3-ap-southeast-1.amazonaws.com/iqoption86x60.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      CUENTA DE PRUEBA GRATUITA
                      </div>
                      <div className="stt-su">
                      Pago: <b>Hasta 100 %! *</b>
                      </div>
                      <div className="btn-p2">
                        <a href="/resena-de-iq-option-es-un-broker-de-estafa-o-legitimo" className="btn-1">Reseña</a>
                        <a href="https://link.invertiry.com/iqoption" rel="nofollow" className="btn-2">¡ABRIR CUENTA!</a>
                      </div>
                      <div className="stt-su">
                      ADVERTENCIA DE RIESGO: SU CAPITAL PUEDE ESTAR EN RIESGO
                      </div>
                    </div>
                  </div>  

                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.invertiry.com/olymptrade" rel="nofollow" title="olymptrade indo">
                        <img alt="olymptrade indo" src="https://imgdmf5.s3-ap-southeast-1.amazonaws.com/olymptrade-logo.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      Obtenga $ 10,000 gratis para la cuenta DEMO
                      </div>
                      <div className="stt-su">
                      Pago: <b>82% para Standard, 90% para cuentas VIP *</b>
                      </div>
                      <div className="btn-p2">
                        <a href="/resena-de-olymp-trade-corredor-legitimo-o-una-estafa" className="btn-1">Reseña</a>
                        <a href="https://link.invertiry.com/olymptrade" rel="nofollow" className="btn-2">¡ABRIR CUENTA!</a>
                      </div>
                    </div>
                  </div> 

                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.invertiry.com/binomo" rel="nofollow" title="binomo">
                        <img alt="binomo indo" src="https://imgdmf5.s3-ap-southeast-1.amazonaws.com/binomo86x86.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      DEMO GRATUITA Y DEPÓSITO BAJO MIN
                      </div>
                      <div className="stt-su">
                      Pago: <b>Hasta 90 %!*</b>
                      </div>
                      <div className="btn-p2">
                        <a href="/resena-de-binomo-es-un-broker-de-estafa-o-legitimo" className="btn-1">Reseña</a>
                        <a href="https://link.invertiry.com/binomo" rel="nofollow" className="btn-2">¡ABRIR CUENTA!</a>
                      </div>
                    </div>
                  </div>  

                  <div className="row align-items-center text-md-left text-center">
                    <div className="col-md-4">
                      <a href="https://link.invertiry.com/expertoption" rel="nofollow" title="expertoption">
                        <img alt="expertoption indo" src="https://dmf5.xyz/Media/images/20200516/daftar-broker-opsi-biner-terbaik-di-indonesia-1-233927.png" />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="stt">
                      Funciones de Trading Social
                      </div>
                      <div className="stt-su">
                      Pago: <b>Hasta 95 %*</b>
                      </div>
                      <div className="btn-p2">
                        <a href="/resena-de-expert-option-es-una-estafa-del-trading-o-una-empresa-confiable" className="btn-1">Reseña</a>
                        <a href="https://link.invertiry.com/expertoption" rel="nofollow" className="btn-2">¡ABRIR CUENTA!</a>
                      </div>
                    </div>
                  </div>   
                  
                  <div className="stt-su">
                    <div className="col-md-12">
                    ADVERTENCIA DE RIESGO: SU CAPITAL PUEDE ESTAR EN RIESGO
                    </div>
                  </div>   

                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Sidebar